import React, { Component, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../components/sidebar/SideBar";
import Content from "../components/content/Content";
import Dashboard from "../components/dashboard/Dashboard";
import Footer from "../components/Footer/Footer";
import TopNav from "../components/TopNav/TopNav";

class HomePage extends Component {

  render() {
    return (
      <Fragment>
        <TopNav></TopNav>
        <Dashboard></Dashboard>
        <Footer></Footer>
      </Fragment>
    );
  }
  
}

export default HomePage;

import React, { Component, Fragment } from "react";
import { Container, Col, Row, Form, Button, Card, NavLink } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import {
  removeUserSession,
  setUserSession,
  getToken,
} from "../components/Common/Common.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loading from '../components/Loading/Loading'
import RestClient from '../components/restapi/RestClient'
import AppUrl from '../components/restapi/AppUrl'

class LoginPage extends Component {
    constructor() {
        super();
        this.state = {
          mydata: [],
          loading: false,
          error: false,
          message: "",
          loggedin:false
        };
    
        this.redirecttohome = this.redirecttohome.bind(this);
      }
    
      componentDidMount() {
        window.scroll(0, 0);
        //removeUserSession();
      }
    
      errorToast = (a) =>
      toast.error("🦄 " + a, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    successToast = (a) =>
      toast.success("🦄 " + a, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        logout:false
      });
    
      redirecttohome(){
        console.log("done")
        return <Redirect to="/"></Redirect>;
      };
    
      onLoginClick = (e) => {
    
        e.preventDefault();
    
        let email = document.getElementById("email").value;
        let password = document.getElementById("password").value;
    
        if (email != null && email.length == 0) {
          return this.errorToast("Please write email address");
        }
    
        if (password != null && password.length == 0) {
          return this.errorToast("Please write password");
        }
    
        this.setState({ loading: true });
    
        let jsonObject = { emailID: email, password: password };
        console.log(jsonObject);
        RestClient.PostRequest(AppUrl.onLogin, JSON.stringify(jsonObject))
          .then((result) => {

            console.log(result);
            if (result == null) {
              this.setState({ error: true, loading: false });
            } else if (result.success == "0") {
              this.setState({ error: false, loading: false });
              this.setState({ message: "No account found on server" });
              this.errorToast("No account found on server");
              // alert("No account found on server");
            } else {
              this.setState({ mydata: result.data, loading: false, error: false });
    
              if (result.data[0]["status"] != "ACTIVE") {
                this.setState({ message: "Your account is not active yet" });
    
                this.errorToast("Your account is not active yet");
                //alert("Your account is not active yet");
              } else {
                setUserSession(result.token, result.data);
               // this.props.history.push("/home");
                //<Redirect to="/login"></Redirect>
    
                this.successToast("Logged in Successfully");
    
                this.setState({loggedin:true})
              }
            }
          })
          .catch((error) => {
            this.setState({ error: true, loading: false });
            console.log(error);
          });
      };

      render() {


        if(getToken() != null){
    
          return <Redirect to="/"></Redirect>;
    
        }else if(this.state.loggedin == true){
    
          return <Redirect to="/"></Redirect>
        }else if (this.state.loading == true) {
          return <Loading></Loading>;
        } else 
        // if (this.state.error == false)
         {
          return (
            <Fragment>
              {/* <h1 className="text-center mt-5 logintext">Speaking Audios</h1> */}
    
              {/* <Typical
                steps={["Hello", 1000, "Hello world!", 500]}
                loop={Infinity}
                wrapper="p"
              /> */}
              <Container className="centered">
                <Row>
                  <Col sm={12} md={12} lg={12}>
                  <ToastContainer />
                    <Card
                      className="shadow-lg p-3 mb-5 bg-white rounded"
                      style={{ width: "25rem" }}
                    >
                      <Card.Body>
                        <Card.Title>Login</Card.Title>
                        <Form onSubmit={this.onLoginClick}>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              id="email"
                              type="text"
                              placeholder="Enter your email"
                            />
                          </Form.Group>
    
                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              id="password"
                              type="password"
                              placeholder="Enter your password"
                            />
                          </Form.Group>
                          <Button type="submit" variant="primary">
                            Submit
                          </Button>
                        </Form>
                      </Card.Body>
                    </Card>
    
                    <h1 className="white">{this.state.message}</h1>
                  </Col>
                </Row>
              </Container>
            </Fragment>
          );
        }
      }
}

export default LoginPage;

import React, { Component, Fragment } from "react";

import TopNav from "../components/TopNav/TopNav";
import Footer from "../components/Footer/Footer";
import RadioContent from "../components/RadioContent/RadioContent";

class RadioPage extends Component {
  render() {
    return (
      <Fragment>
        <TopNav></TopNav>

        <RadioContent></RadioContent>
        <Footer></Footer>
      </Fragment>
    );
  }
}

export default RadioPage;

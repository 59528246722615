import React, { Component, Fragment } from 'react';
import AllUserContent from '../components/AllUserContent/AllUserContent';
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer';

class AllUsersPage extends Component {





  render() {

    return (
      <Fragment>
        
        <TopNav></TopNav>
        <AllUserContent></AllUserContent>
        <Footer></Footer>
        
      </Fragment>
    );
  }
}

export default AllUsersPage;
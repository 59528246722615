import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "../pages/HomePage";
import Dashboard from "../components/dashboard/Dashboard";
import TopSliderPage from "../pages/TopSliderPage";
import TeachersPage from "../pages/TeachersPage";
import RadioPage from "../pages/RadioPage";
import CueCardPage from "../pages/CueCardPage";
import AllUsersPage from "../pages/AllUsersPage";
import IELTSVideoPage from "../pages/IELTSVideoPage";
import EnglishVideoPage from "../pages/EnglishVideoPage";
import LoginPage from "../pages/LoginPage";

class AppRouter extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route exact path="/topslider" component={TopSliderPage}></Route>
          <Route exact path="/teachers" component={TeachersPage}></Route>
          <Route exact path="/radio" component={RadioPage}></Route>
          <Route exact path="/cuecard" component={CueCardPage}></Route>
          <Route exact path="/allusers" component={AllUsersPage}></Route>
          <Route exact path="/ieltsvieos" component={IELTSVideoPage}></Route>
          <Route exact path="/englishvideos" component={EnglishVideoPage}></Route>
          <Route exact path="/login" component={LoginPage}></Route>
        </Switch>
      </Fragment>
    );
  }
}

export default AppRouter;

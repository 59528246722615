import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "../Loading/Loading";
import RestClient from "../restapi/RestClient";
import AppUrl from "../restapi/AppUrl";
import { withRouter, Redirect, Link } from "react-router-dom";
import { getToken } from "../Common/Common";
class TeachersContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: 1,
      total: 0,
      per_page: 10,
      totalusercount: "0",
      totalnewusercount: "0",
      totaluncheckedaudio: "0",
      todayuploadedaudio: "0",
      mydata: [],
      deleteshow: false,
      editshow: false,
      addshow: false,
      loading: true,
      error: false,
      selectedItem: {
        id: 0,
        status: "",
        creationDate: "",
        name: "",
        emailID: "",
        password: "",
        profileImage: "",
        isAdmin: 0,
      },
      loggedin: false,
      access_token: "",
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);

    this.deleteRow = this.deleteRow.bind(this);
    this.editRow = this.editRow.bind(this);
  }

  statusChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        status: e.target.value,
      },
    });
  }

  creationDateChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        creationDate: e.target.value,
      },
    });
  }

  nameChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        name: e.target.value,
      },
    });
  }

  emailIDChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        emailID: e.target.value,
      },
    });
  }

  passwordChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        password: e.target.value,
      },
    });
  }

  profileImageChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        profileImage: e.target.value,
      },
    });
  }

  isAdminChange(e) {
    this.setState({
      selectedItem: {
        ...this.state.selectedItem,
        isAdmin: e.target.value,
      },
    });
  }

  errorToast = (a) =>
    toast.error("🦄 " + a, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  successToast = (a) =>
    toast.success("🦄 " + a, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  addCard = () => {
    if (this.state.selectedItem.status == "") {
      alert("Please write status");
      return;
    }

    if (this.state.selectedItem.creationDate == "") {
      alert("Please Write creationDate");
      return;
    }
    if (this.state.selectedItem.name == "") {
      alert("Please Write name");
      return;
    }
    if (this.state.selectedItem.emailID == "") {
      alert("Please Write emailID");
      return;
    }

    if (this.state.selectedItem.password == "") {
      alert("Please Write password");
      return;
    }

    if (this.state.selectedItem.profileImage == "") {
      alert("Please Write profileImage");
      return;
    }

    if (this.state.selectedItem.isAdmin == "") {
      alert("Please Write isAdmin");
      return;
    }

    this.setState({ addshow: false });

    const token = getToken();

    let jsonObject = {
      status: this.state.selectedItem.status,
      creationDate: this.state.selectedItem.creationDate,
      name: this.state.selectedItem.name,
      emailID: this.state.selectedItem.emailID,
      password: this.state.selectedItem.password,
      profileImage: this.state.selectedItem.profileImage,
      isAdmin: this.state.selectedItem.isAdmin,
      access_token: token,
    };
    RestClient.PostRequest(AppUrl.InsertTeachers, JSON.stringify(jsonObject))
      .then((result) => {
        if (result == null) {
          //this.setState({ error: true, loading: false });
          this.errorToast("Something wrong in server");
          this.setState({ error: true, loading: false });
        } else if (result.success == "0") {
          //this.setState({ error: false, loading: false });
          //this.setState({ message: "No account found on server" });
          // alert("No account found on server");
          //alert("Something wrong in server");
          this.errorToast("Something wrong in server");
          this.setState({ error: true, loading: false });
        } else {
          this.successToast("Successfully saved in Database");
          //this.setState({ mydata: result.data, loading: false, error: false });
          this.setState({ loading: false });

          this.getAllTopSlider();
        }
      })
      .catch((error) => {
        //this.setState({ error: true, loading: false });
        this.setState({ error: true, loading: false });
      });
  };

  addRow = () => {
    let newAccess = this.state.selectedItem;
    newAccess.id = 0;
    newAccess.status = "";
    newAccess.creationDate = "";
    newAccess.name = "";
    newAccess.emailID = "";
    newAccess.password = "";
    newAccess.profileImage = "";
    newAccess.isAdmin = 0;
    this.setState({ selectedItem: newAccess });

    this.setState({ addshow: true });
  };

  addClose = () => {
    this.setState({ addshow: false });
  };

  editRow = (a) => {
    this.setState({ selectedItem: a });
    this.setState({ editshow: true });
  };

  editClose = () => {
    this.setState({ editshow: false });
  };

  updateEdited = () => {
    if (this.state.selectedItem.title == "") {
      alert("Please write Title");
      return;
    }

    if (this.state.selectedItem.image_url == "") {
      alert("Please Write image_url");
      return;
    }

    if (this.state.selectedItem.isImage == "") {
      alert("Please Write isImage");
      return;
    }

    if (this.state.selectedItem.isForSpeaking == "") {
      alert("Please Write isForSpeaking");
      return;
    }

    this.setState({ editshow: false });

    const token = getToken();

    let jsonObject = {
      id: this.state.selectedItem.id,
      status: this.state.selectedItem.status,
      creationDate: this.state.selectedItem.creationDate,
      name: this.state.selectedItem.name,
      emailID: this.state.selectedItem.emailID,
      password: this.state.selectedItem.password,
      profileImage: this.state.selectedItem.profileImage,
      isAdmin: this.state.selectedItem.isAdmin,
      access_token: token,
    };
    RestClient.PostRequest(AppUrl.updateTeachers, JSON.stringify(jsonObject))
      .then((result) => {
        if (result == null) {
          //this.setState({ error: true, loading: false });
          this.errorToast("Something wrong in server");
          this.setState({ error: true, loading: false });
        } else if (result.success == "0") {
          //this.setState({ error: false, loading: false });
          //this.setState({ message: "No account found on server" });
          // alert("No account found on server");
          //alert("Something wrong in server");
          this.errorToast("Something wrong in server");
          this.setState({ error: true, loading: false });
        } else {
          //alert("Successfully Updated in Database");
          this.successToast("Successfully Updated in Database");
          //this.setState({ mydata: result.data, loading: false, error: false });
          this.setState({ loading: false });

          this.getAllTopSlider();
        }
      })
      .catch((error) => {
        //this.setState({ error: true, loading: false });
        this.setState({ error: true, loading: false });
      });
  };

  deleteClose = () => this.setState({ deleteshow: false });
  //handleShow = () => this.setState({ deleteshow: true });

  deleteRow = (a) => {
    this.setState({ selectedItem: a });
    this.setState({ deleteshow: true });
  };

  deleteCueCard = () => {
    this.setState({ deleteshow: false });

    const token = getToken();

    let jsonObject = {
      id: this.state.selectedItem.id,
      access_token: token,
    };
    RestClient.PostRequest(
      AppUrl.deleteTeacherByID,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null) {
          this.errorToast("Something wrong in server");
          this.setState({ error: true, loading: false });
          //this.setState({ error: true, loading: false });
        } else if (result.success == "0") {
          //this.setState({ error: false, loading: false });
          //this.setState({ message: "No account found on server" });
          // alert("No account found on server");
          //alert("Something wrong in server");
          this.errorToast("Something wrong in server");
          this.setState({ error: true, loading: false });
        } else {
          //alert("Successfully Deleted in Database");
          this.successToast("Successfully Deleted in Database");
          //this.setState({ mydata: result.data, loading: false, error: false });

          this.setState({ loading: false });

          this.getAllTopSlider();
        }
      })
      .catch((error) => {
        //this.setState({ error: true, loading: false });
        this.setState({ error: true, loading: false });
      });
  };

  onPageChanged = (e) => {
    console.log(e.currentPage);
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ current_page: pageNumber });
    this.getAllTopSlider(pageNumber);
  }

  componentDidMount() {
    // const token = getToken();
    // this.setState({access_token:token});

    this.getAllTopSlider();
  }

  getAllTopSlider() {
    const token = getToken();
    let jsonObject = { access_token: token };
    RestClient.PostRequest(AppUrl.getAllTeachers, JSON.stringify(jsonObject))
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            mydata: result.data.data,
            total: result.data.total,
            per_page: result.data.per_page,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  render() {
    if (getToken() == null) {
      return <Redirect to="/login"></Redirect>;
    } else if (this.state.loading == true) {
      return <Loading></Loading>;
    } else if (this.state.error == false) {
      const mylist = this.state.mydata;
      const myview = mylist.map((myList1) => {
        return (
          <tr>
            <td className="customcolor">{myList1.id}</td>
            <td className="customcolor">{myList1.status}</td>
            <td className="customcolor">{myList1.creationDate}</td>
            {/* <img className="customcolor" src={myList1.image_url} alt="No image available" width="100" height="100"></img> */}
            <td className="customcolor">{myList1.name}</td>
            <td className="customcolor">{myList1.emailID}</td>
            <td className="customcolor">{myList1.password}</td>
            <td className="customcolor">{myList1.profileImage}</td>
            <td className="customcolor">{myList1.isAdmin}</td>
            <td className="text-center">
              <Button
                onClick={this.editRow.bind(this, myList1)}
                variant="primary"
              >
                Edit
              </Button>
            </td>
            <td className="text-center">
              <Button
                onClick={this.deleteRow.bind(this, myList1)}
                variant="danger"
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });

      return (
        <Fragment>
          <Container className="mt-5 mb-5">
            <Row>
              <Col sm={12} md={12} lg={12}>
                <ToastContainer />
                <Button
                  onClick={this.addRow.bind(this)}
                  variant="success"
                  className="float-right"
                >
                  Add Teacher
                </Button>
                <br></br>
                <br></br>
                <br></br>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="white">id</th>
                      <th className="white">status</th>
                      <th className="white">creationDate</th>
                      <th className="white">name</th>
                      <th className="white">emailID</th>
                      <th className="white">password</th>
                      <th className="white">profileImage</th>
                      <th className="white">isAdmin</th>
                    </tr>
                  </thead>
                  <tbody>{myview}</tbody>
                </Table>
                <Modal show={this.state.deleteshow} onHide={this.deleteClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{this.state.selectedItem.id}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{this.state.selectedItem.name}</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.deleteClose}>
                      Close
                    </Button>
                    <Button variant="danger" onClick={this.deleteCueCard}>
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal show={this.state.editshow} onHide={this.editClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Teacher</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card className=" p-3 mb-5 bg-white rounded">
                      <Card.Body>
                        <Form>
                          <Form.Group>
                            <Form.Label>status</Form.Label>
                            <Form.Control
                              id="EcategoryID"
                              type="text"
                              placeholder="Enter status"
                              value={this.state.selectedItem.status}
                              onChange={(e) => this.statusChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>creationDate</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter creationDate"
                              value={this.state.selectedItem.creationDate}
                              onChange={(e) => this.creationDateChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>name</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter name"
                              value={this.state.selectedItem.name}
                              onChange={(e) => this.nameChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>emailID</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter emailID"
                              value={this.state.selectedItem.emailID}
                              onChange={(e) => this.emailIDChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>password</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter password"
                              value={this.state.selectedItem.password}
                              onChange={(e) => this.passwordChange(e)}
                            />
                          </Form.Group>


                          <Form.Group>
                            <Form.Label>profileImage</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter profileImage"
                              value={this.state.selectedItem.profileImage}
                              onChange={(e) => this.profileImageChange(e)}
                            />
                          </Form.Group>



                          <Form.Group>
                            <Form.Label>isAdmin</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter isAdmin"
                              value={this.state.selectedItem.isAdmin}
                              onChange={(e) => this.isAdminChange(e)}
                            />
                          </Form.Group>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.editClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={this.updateEdited}>
                      Update
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal show={this.state.addshow} onHide={this.addClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add Teacher</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card className=" p-3 mb-5 bg-white rounded">
                      <Card.Body>
                      <Form>
                          <Form.Group>
                            <Form.Label>status</Form.Label>
                            <Form.Control
                              id="EcategoryID"
                              type="text"
                              placeholder="Enter status"
                              value={this.state.selectedItem.status}
                              onChange={(e) => this.statusChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>creationDate</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter creationDate"
                              value={this.state.selectedItem.creationDate}
                              onChange={(e) => this.creationDateChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>name</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter name"
                              value={this.state.selectedItem.name}
                              onChange={(e) => this.nameChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>emailID</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter emailID"
                              value={this.state.selectedItem.emailID}
                              onChange={(e) => this.emailIDChange(e)}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>password</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter password"
                              value={this.state.selectedItem.password}
                              onChange={(e) => this.passwordChange(e)}
                            />
                          </Form.Group>


                          <Form.Group>
                            <Form.Label>profileImage</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter profileImage"
                              value={this.state.selectedItem.profileImage}
                              onChange={(e) => this.profileImageChange(e)}
                            />
                          </Form.Group>



                          <Form.Group>
                            <Form.Label>isAdmin</Form.Label>
                            <Form.Control
                              id="Evideoid"
                              type="text"
                              placeholder="Enter isAdmin"
                              value={this.state.selectedItem.isAdmin}
                              onChange={(e) => this.isAdminChange(e)}
                            />
                          </Form.Group>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.addClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={this.addCard}>
                      Add Teacher
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Pagination
                  activePage={this.state.current_page}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total}
                  pageRangeDisplayed={10}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                  nextPageText="Next"
                  prevPageText="Prev"
                  onChange={this.handlePageChange.bind(this)}
                />
              </Col>
            </Row>
          </Container>
        </Fragment>
      );
    }
  }
}

export default TeachersContent;

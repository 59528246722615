import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Card,
  Form,
  Toast,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RestClient from "../restapi/RestClient";
import AppUrl from "../restapi/AppUrl";
import Loading from "../Loading/Loading";
import { withRouter, Redirect, Link } from "react-router-dom";
import { getToken } from "../Common/Common";


class CueCardContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
          current_page: 1,
          total: 0,
          per_page: 10,
          data: [],
          deleteshow: false,
          editshow: false,
          addshow: false,
          loading: true,
          error:false,
          selectedItem: {
            id: 0,
            categoryID: "",
            videoid: "",
          },
          loggedin:false,
          access_token:""
        };
        this.deleteRow = this.deleteRow.bind(this);
        this.editRow = this.editRow.bind(this);
      }
    
      componentDidMount() {
        // const token = getToken();
        // this.setState({access_token:token});
        this.getLatestCueCard();
      }
    
      handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ current_page: pageNumber });
        this.getLatestCueCard(pageNumber);
      }
    
      getLatestCueCard(pageNumber = 1) {
        const token = getToken();
        let jsonObject = {access_token: token};
        RestClient.PostRequest(AppUrl.getAllLatestCueCard + pageNumber,
          JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null || result.success == "0") {
              this.setState({ error: true,loading:false });
            } else {
              this.setState({
                data: result.data.data,
                total: result.data.total,
                per_page: result.data.per_page,
                loading:false
              });
            }
          })
          .catch((error) => {
            this.setState({ error: true ,loading:false});
          });
      }
    
      categoryIDChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            categoryID: e.target.value,
          },
        });
    
    
      }
    
      videoidChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            videoid: e.target.value,
          },
        });
    
      }
    
    
       errorToast = (a) => toast.error('🦄 ' +a, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
       successToast = (a) => toast.success('🦄 ' +a, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    
    
    
    
      addCard = () => {
    
       
        if (this.state.selectedItem.categoryID == "") {
          alert("Please write Title");
          return;
        }
    
        if (this.state.selectedItem.videoid == "") {
          alert("Please Write Date");
          return;
        }
    
        this.setState({ addshow: false });

        const token = getToken();
    
        let jsonObject = {
          categoryID: this.state.selectedItem.categoryID,
          videoid: this.state.selectedItem.videoid,
          vtitle: "",
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.InsertLatestCueCard,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.setState({ error: true, loading: false });
              this.errorToast("Something wrong in server")
    
            } else if (result.success == "0") {
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.setState({loading: false });
              this.errorToast("Something wrong in server")
            } else {
              this.setState({loading:false})
              this.successToast("Successfully saved in Database")
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getLatestCueCard();
            }
          })
          .catch((error) => {
            this.setState({ error: true, loading: false });
          });
      };
    
      addRow = () => {
    
       
    
        let newAccess = this.state.selectedItem;
        newAccess.id = 0;
        newAccess.categoryID = "";
        newAccess.videoid = "";
        this.setState({ selectedItem: newAccess });
    
        this.setState({ addshow: true });
      };
    
      addClose = () => {
        this.setState({ addshow: false });
      };
    
      editRow = (a) => {
        this.setState({ selectedItem: a });
        this.setState({ editshow: true });
      };
    
      editClose = () => {
        this.setState({ editshow: false });
      };
    
      updateEdited = () => {
        if (this.state.selectedItem.categoryID == "") {
          alert("Please write Title");
          return;
        }
    
        if (this.state.selectedItem.videoid == "") {
          alert("Please Write Date");
          return;
        }
    
        this.setState({ editshow: false });

        const token = getToken();
    
        let jsonObject = {
          categoryID: this.state.selectedItem.categoryID,
          videoid: this.state.selectedItem.videoid,
          vtitle: "",
          id: this.state.selectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.updateLatestCueCard,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.setState({ error: true, loading: false });
              this.errorToast("Something wrong in server")
            } else if (result.success == "0") {
              this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server")
            } else {
              this.setState({loading: false });
              //alert("Successfully Updated in Database");
              this.successToast("Successfully Updated in Database")
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getLatestCueCard();
            }
          })
          .catch((error) => {
            this.setState({ error: true, loading: false });
          });
      };
    
      deleteClose = () => this.setState({ deleteshow: false });
      //handleShow = () => this.setState({ deleteshow: true });
    
      deleteRow = (a) => {
        this.setState({ selectedItem: a });
        this.setState({ deleteshow: true });
      };
    
      deleteCueCard = () => {
        this.setState({ deleteshow: false });

        const token = getToken();
    
        let jsonObject = {
          id: this.state.selectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(AppUrl.deleteCueCardByID, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null) {
              this.errorToast("Something wrong in server")
              this.setState({ error: true, loading: false });
            } else if (result.success == "0") {
              this.setState({loading:false})
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server")
            } else {
              this.setState({loading:false})
              //alert("Successfully Deleted in Database");
              this.successToast("Successfully Deleted in Database")
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getLatestCueCard();
            }
          })
          .catch((error) => {
            this.setState({ error: true, loading: false });
          });
      };




      render() {
        if (getToken() == null) {
          return <Redirect to="/login"></Redirect>;
        }else if (this.state.loading == true) {
          return <Loading></Loading>;
        } else if(this.state.error == false) {
    
        const mylist = this.state.data;
        const myview = mylist.map((myList1) => {
          return (
            <tr>
              <td className="customcolor">{myList1.id}</td>
              <td className="customcolor">{myList1.categoryID}</td>
              <td className="customcolor">{myList1.videoid}</td>
              <td className="text-center">
                <Button
                  onClick={this.editRow.bind(this, myList1)}
                  variant="primary"
                >
                  Edit
                </Button>
              </td>
              <td className="text-center">
                <Button
                  onClick={this.deleteRow.bind(this, myList1)}
                  variant="danger"
                >
                  Delete
                </Button>
              </td>
            </tr>
          );
        });
    
        return (
          <Fragment>
            <Container className="mt-5 mb-5">
              <Row>
                <Col sm={12} md={12} lg={12}>
    
                <ToastContainer />
                  <Button
                    onClick={this.addRow.bind(this)}
                    variant="success"
                    className="float-right"
                  >
                    Add CueCard
                  </Button>
    
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="white">id</th>
                        <th className="white">categoryID</th>
                        <th className="white">videoid</th>
                      </tr>
                    </thead>
                    <tbody>{myview}</tbody>
                  </Table>
    
                  <Modal show={this.state.deleteshow} onHide={this.deleteClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.state.selectedItem.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.selectedItem.categoryID}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.deleteClose}>
                        Close
                      </Button>
                      <Button variant="danger" onClick={this.deleteCueCard}>
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
    
                  <Modal show={this.state.editshow} onHide={this.editClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Cue Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card className=" p-3 mb-5 bg-white rounded">
                        <Card.Body>
                          <Form>
                            <Form.Group>
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                id="EcategoryID"
                                type="text"
                                placeholder="Enter title"
                                value={this.state.selectedItem.categoryID}
                                onChange={(e) => this.categoryIDChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>Date</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter date"
                                value={this.state.selectedItem.videoid}
                                onChange={(e) => this.videoidChange(e)}
                              />
                            </Form.Group>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.editClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.updateEdited}>
                        Update
                      </Button>
                    </Modal.Footer>
                  </Modal>
    
                  <Modal show={this.state.addshow} onHide={this.addClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Cue Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card className=" p-3 mb-5 bg-white rounded">
                        <Card.Body>
                          <Form>
                            <Form.Group>
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                id="EcategoryID"
                                type="text"
                                placeholder="Enter title"
                                value={this.state.selectedItem.categoryID}
                                onChange={(e) => this.categoryIDChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>Date</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter date"
                                value={this.state.selectedItem.videoid}
                                onChange={(e) => this.videoidChange(e)}
                              />
                            </Form.Group>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.addClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.addCard}>
                        Add CueCard
                      </Button>
                    </Modal.Footer>
                  </Modal>
    
                  <Pagination
                    activePage={this.state.current_page}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total}
                    pageRangeDisplayed={10}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                    nextPageText="Next"
                    prevPageText="Prev"
                    onChange={this.handlePageChange.bind(this)}
                  />
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
        }
      }
}

export default CueCardContent;
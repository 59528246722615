import React, { Component } from "react";
import classNames from "classnames";
import { Container } from "react-bootstrap";
import NavigationBar from "./NavigationBar";
import { Switch, Route } from "react-router-dom";
import Dashboard from '../dashboard/Dashboard'
import TopSliderPage from "../../pages/TopSliderPage";
import TeachersPage from "../../pages/TeachersPage";
import RadioPage from "../../pages/RadioPage";
import CueCardPage from "../../pages/CueCardPage";
import AllUsersPage from "../../pages/AllUsersPage";
import IELTSVideoPage from "../../pages/IELTSVideoPage";
import EnglishVideoPage from "../../pages/EnglishVideoPage";



class Content extends Component {
  render() {
    return (
      <Container
        fluid
        className={classNames("content", { "is-open": this.props.isOpen })}
      >
        <NavigationBar toggle={this.props.toggle} />


        <Switch>
          <Route exact path="/" component={Dashboard}></Route>
          <Route exact path="/topslider" component={TopSliderPage}></Route>
          <Route exact path="/teachers" component={TeachersPage}></Route>
          <Route exact path="/radio" component={RadioPage}></Route>
          <Route exact path="/cuecard" component={CueCardPage}></Route>
          <Route exact path="/users" component={AllUsersPage}></Route>
          <Route exact path="/ieltsvideos" component={IELTSVideoPage}></Route>
          <Route exact path="/englishvideos" component={EnglishVideoPage}></Route>
        
        </Switch>

        
      </Container>
    );
  }
}

export default Content;

import React, { Component, Fragment } from "react";
import { Navbar, Nav, NavDropdown, Button, NavItem } from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";
import { removeUserSession, getToken } from "../Common/Common";
import '../../asset/css/custom.css'

class TopNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logout: false
    }
  }

  logout = () => {
    removeUserSession();
    this.setState({logout:true})
  };

  render() {
    if (this.state.logout==true) {
      return <Redirect to="/login"></Redirect>;
    } else {
      return (
        <Fragment>
          <Navbar bg="dark" variant="dark">
            <Navbar.Brand>
              <img
                src="https://cdn.pixabay.com/photo/2017/02/27/20/48/headphones-2104207_960_720.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Nav className="mr-auto justify-content-center">
              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/">Home</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/topslider">Top Slider</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/teachers">Teachers</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/radio">Radio</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/cuecard">CueCard</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/allusers">All Users</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/ieltsvieos">IELTS Videos</NavLink>
              </Nav.Link>

              <Nav.Link >
                <NavLink exact activeStyle={{ color:'white' }} to="/englishvideos">English Videos</NavLink>
              </Nav.Link>

              <NavItem>
                <Button color="light" onClick={this.logout}>
                  Logout
                </Button>
              </NavItem>
            </Nav>
          </Navbar>
        </Fragment>
      );
    }
  }
}

export default TopNav;

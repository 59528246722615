import React, { Component, Fragment } from "react";
import RestClient from "../restapi/RestClient";
import AppUrl from "../restapi/AppUrl";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../Loading/Loading";
import "../../App.css";
import { withRouter, Redirect, Link } from "react-router-dom";
import { getToken } from "../Common/Common";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalusercount: "0",
      totalnewusercount: "0",
      totaluncheckedaudio: "0",
      todayuploadedaudio: "0",
      TotalEngCatCount: "0",
      TotalEngVideoCount: "0",
      TotalIELTSVideoCount: "0",
      TotalIELTSCatCount: "0",
      mydata: [],
      loading: true,
      error: false,
      loggedin: false,
      access_token: "",
    };
  }

  componentDidMount() {
    this.gettotalusercount();
    this.gettodaynewuserCount();
    this.gettotaluncheckAudioCount();
    this.gettodayUploadAudioCount();
    this.getTotalEngCatCount();
    this.getTotalEngVideoCount();
    this.getTotalIELTSVideoCount();
    this.getTotalIELTSCatCount();
  }

  gettodayUploadAudioCount() {
    const token = getToken();

    var d = new Date().toISOString().slice(0, 10);

    let jsonObject = { creationDate: d, access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalTodayAudioCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            todayuploadedaudio: result.data,
            loading: false,
            error: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  gettotaluncheckAudioCount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalUncheckedAudioCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            totaluncheckedaudio: result.data,
            loading: false,
            error: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  gettodaynewuserCount() {
    const token = getToken();

    var d = new Date().toISOString().slice(0, 10);

    let jsonObject = { creationDate: d, access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalNewTodayUserCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            totalnewusercount: result.data,
            loading: false,
            error: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  gettotalusercount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(AppUrl.getTotalUserCount, JSON.stringify(jsonObject))
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            totalusercount: result.data,
            error: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  getTotalEngCatCount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalEngCatCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            TotalEngCatCount: result.data,
            error: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  getTotalEngVideoCount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalEngVideoCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            TotalEngVideoCount: result.data,
            error: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  getTotalEngVideoCount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalEngVideoCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            TotalEngVideoCount: result.data,
            error: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  getTotalIELTSVideoCount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalIELTSVideoCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            TotalIELTSVideoCount: result.data,
            error: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  getTotalIELTSCatCount() {
    const token = getToken();

    let jsonObject = { access_token: token };
    RestClient.PostRequest(
      AppUrl.getTotalIELTSCatCount,
      JSON.stringify(jsonObject)
    )
      .then((result) => {
        if (result == null || result.success == "0") {
          this.setState({ error: true, loading: false });
        } else {
          this.setState({
            TotalIELTSCatCount: result.data,
            error: false,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }

  render() {
    // if (this.state.loading == true) {
    //   return <Loading></Loading>;
    // } else if (this.state.error == false) {

    if (getToken() == null) {
      return <Redirect to="/login"></Redirect>;
    } else {
      return (
        <Fragment>
          <Container className="mt-5 mb-5">
            <h4 className="text-center white"><img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img> User Info <img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img></h4>
            <br></br>
            <br></br>
            <Row>
              <Col
                className="text-center shadow bg-black rounded "
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">Total User</h4>
                <h5 className="darkorange">{this.state.totalusercount}</h5>
              </Col>

              <Col
                className="text-center shadowbg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">New User</h4>
                <h5 className="darkorange">{this.state.totalnewusercount}</h5>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <h4 className="text-center white"><img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img>User Audios<img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img></h4>
            <br></br>
            <br></br>
            <Row>
              <Col
                className="text-center shadow bg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">Unchecked Audio</h4>
                <h5 className="darkorange">{this.state.totaluncheckedaudio}</h5>
              </Col>

              <Col
                className="text-center shadow bg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor ">Today Upload</h4>
                <h5 className="darkorange">{this.state.todayuploadedaudio}</h5>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <h4 className="text-center white"><img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img>English Videos<img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img></h4>
            <br></br>
            <br></br>
            <Row>
              <Col
                className="text-center shadow bg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">Total Categories</h4>
                <h5 className="darkorange">{this.state.TotalEngCatCount}</h5>
              </Col>

              <Col
                className="text-center shadow bg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">Total Videos</h4>
                <h5 className="darkorange">{this.state.TotalEngVideoCount}</h5>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <h4 className="text-center white"><img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img>IELTS Videos<img width="30" height="30" src="https://cdn.pixabay.com/photo/2014/04/02/11/01/firework-305244_960_720.png"></img></h4>
            <br></br>
            <br></br>
            <Row>
              <Col
                className="text-center shadow bg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">Total Categories</h4>
                <h5 className="darkorange">{this.state.TotalIELTSCatCount}</h5>
              </Col>

              <Col
                className="text-center shadow bg-black rounded"
                sm={12}
                md={6}
                lg={6}
              >
                <h4 className="customcolor">Total Videos</h4>
                <h5 className="darkorange">{this.state.TotalIELTSVideoCount}</h5>
              </Col>
            </Row>
          </Container>
        </Fragment>
      );
    }
  }
}

export default Dashboard;

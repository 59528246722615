import React, { Component, Fragment } from "react";
import { Container } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="text-center footer dark">
          <p className="footertext">Copyright @IELTS Speaking 2020</p>
        </Container>
      </Fragment>
    );
  }
}

export default Footer;

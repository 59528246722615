import React, { Component, Fragment } from 'react';
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer';
import TeachersContent from '../components/TeachersContent/TeachersContent';
class TeachersPage extends Component {
    render() {
        return (
            <Fragment>
                <TopNav></TopNav>
                <TeachersContent></TeachersContent>
                <Footer></Footer>
            </Fragment>
        );
    }
}

export default TeachersPage;
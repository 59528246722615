import React, { Component, Fragment } from 'react';
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer';
import CueCardContent from '../components/CueCardContent/CueCardContent';
class CueCardPage extends Component {


  render() {
    return (
      <Fragment>

      <TopNav></TopNav>
      <CueCardContent></CueCardContent>
      <Footer></Footer>
        
      </Fragment>
    );
  }
}

export default CueCardPage;
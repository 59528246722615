import React, { Component } from "react";

class AppUrl {

  static baseurl = "https://ieltsadmin.sptv.info/restapiforadmin";

  static getTotalUserCount = this.baseurl + "/getTotalUserCount";
  static getTotalNewTodayUserCount =
    this.baseurl + "/getTotalNewTodayUserCount";
  static getAllUser = this.baseurl + "/getAllUser?page=";

  static getTotalUncheckedAudioCount =
  this.baseurl + "/getTotalUncheckedAudioCount";
  static getTotalTodayAudioCount = this.baseurl + "/getTotalTodayAudioCount";

  static getAllTopSlide = this.baseurl + "/getAllTopSlide";
  static updateTopSlide = this.baseurl + "/updateTopSlide";
  static InsertTopSlide = this.baseurl + "/InsertTopSlide";
  static deleteTopSliderByID = this.baseurl + "/deleteTopSliderByID";

  static getAllRadio = this.baseurl + "/getAllRadio?page=";
  static updateRadio = this.baseurl + "/updateRadio";
  static InsertRadio = this.baseurl + "/InsertRadio";
  static deleteRadioByID = this.baseurl + "/deleteRadioByID";

  static getAllLatestCueCard = this.baseurl + "/getAllLatestCueCard?page=";
  static updateLatestCueCard = this.baseurl + "/updateLatestCueCard";
  static InsertLatestCueCard = this.baseurl + "/InsertLatestCueCard";
  static deleteCueCardByID = this.baseurl + "/deleteCueCardByID";

  static getAllLatestCueCard = this.baseurl + "/getAllLatestCueCard?page=";
  static updateLatestCueCard = this.baseurl + "/updateLatestCueCard";
  static InsertLatestCueCard = this.baseurl + "/InsertLatestCueCard";
  static deleteCueCardByID = this.baseurl + "/deleteCueCardByID";

  static getIELTScatVideos = this.baseurl + "/getIELTScatVideos?page=";
  static updateIELTSCatVideos = this.baseurl + "/updateIELTSCatVideos";
  static InsertIELTSCatVideos = this.baseurl + "/InsertIELTSCatVideos";
  static deleteIELTSCatBYID = this.baseurl + "/deleteIELTSCatBYID";
  static getTotalIELTSCatCount = this.baseurl + "/getTotalIELTSCatCount";

  static getIELTSVideos = this.baseurl + "/getIELTSVideos?page=";
  static updateIELTSVideos = this.baseurl + "/updateIELTSVideos";
  static InsertIELTSVideos = this.baseurl + "/InsertIELTSVideos";
  static deleteIELTSVideoBYID = this.baseurl + "/deleteIELTSVideoBYID";
  static getTotalIELTSVideoCount = this.baseurl + "/getTotalIELTSVideoCount";

  static getEnglishtcatVideos = this.baseurl + "/getEnglishtcatVideos?page=";
  static updateEnglishtCatVideos = this.baseurl + "/updateEnglishtCatVideos";
  static InsertEnglishtCatVideos = this.baseurl + "/InsertEnglishtCatVideos";
  static deleteEnglishtCatBYID = this.baseurl + "/deleteEnglishtCatBYID";
  static getTotalEngCatCount = this.baseurl + "/getTotalEngCatCount";

  static getEnglishVideos = this.baseurl + "/getEnglishVideos?page=";
  static updateEnglishVideos = this.baseurl + "/updateEnglishVideos";
  static InsertEnglishVideos = this.baseurl + "/InsertEnglishVideos";
  static deleteEnglishVideoBYID = this.baseurl + "/deleteEnglishVideoBYID";
  static getTotalEngVideoCount = this.baseurl + "/getTotalEngVideoCount";


  static onLogin=this.baseurl+'/onLogin';
  static getAllTeachers=this.baseurl+'/getAllTeachers?page=';
  static updateTeachers=this.baseurl+'/updateTeachers';
  static InsertTeachers=this.baseurl+'/InsertTeachers';
  static deleteTeacherByID = this.baseurl+'/deleteTeacherByID';
}

export default AppUrl;

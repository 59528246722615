import React, { Component, Fragment } from 'react';
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer';
import EnglishVideosContent from '../components/EnglishVideosContent/EnglishVideosContent';

class EnglishVideoPage extends Component {



  render() {
    return (
      <Fragment>
        <TopNav></TopNav>
        <EnglishVideosContent></EnglishVideosContent>
        <Footer></Footer>
        
      </Fragment>
    );
  }
}

export default EnglishVideoPage;
import React, { Component, Fragment } from 'react';
import TopNav from '../components/TopNav/TopNav';
import TopSliderContent from '../components/TopSliderContent/TopSliderContent';
import Footer from '../components/Footer/Footer';

class TopSliderPage extends Component {
  render() {
    return (
      <Fragment>

        <TopNav></TopNav>
        <TopSliderContent></TopSliderContent>
        <Footer></Footer>
        
      </Fragment>
    );
  }
}

export default TopSliderPage;
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav, NavItem } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

class NavigationBar extends Component {
  render() {
    return (
      <Navbar
        bg="light"
        className="navbar shadow-sm p-3 mb-5 bg-white rounded"
        expand
      >
        <Button variant="outline-info" onClick={this.props.toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" navbar>
            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/">
                Dashboard
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/topslider">
                Top Slider
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/teachers">
                Teachers
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/radio">
                Radio
              </NavLink>
            </Nav.Link>

            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/cuecard">
                CueCard
              </NavLink>
            </Nav.Link>

            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/users">
                All Users
              </NavLink>
            </Nav.Link>
            
            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/ieltsvideos">
                IELTS Videos
              </NavLink>
            </Nav.Link>

            <Nav.Link>
              <NavLink exact activeStyle={{ color: "#00a8ee" }} to="/englishvideos">
                English Videos
              </NavLink>
            </Nav.Link>

            
            <a target="_blank" href="http://ieltsaudioadmin.sptv.info/">Speaking Audio</a>

      
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavigationBar;

import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import AppUrl from "../restapi/AppUrl";
import RestClient from "../restapi/RestClient";
import Image from "react-bootstrap/Image";
import Loading from '../Loading/Loading'
import { withRouter, Redirect, Link } from "react-router-dom";
import { getToken } from "../Common/Common";

class AllUserContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          current_page: 1,
          total: 0,
          per_page: 10,
          data: [],
          loading: true,
          error: false,
          loggedin:false,
          access_token:""
        };
      }
    
      componentDidMount() {

        // const token = getToken();
        // this.setState({access_token:token});
        this.getAllUsers();
      }
    
      handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ current_page: pageNumber });
        this.getAllUsers(pageNumber);
      }
    
      getAllUsers(pageNumber = 1) {

        const token = getToken();

        let jsonObject = {access_token: token};

        RestClient.PostRequest(AppUrl.getAllUser + pageNumber,
          JSON.stringify(jsonObject)
          )
          .then((result) => {
            if (result == null || result.success == "0") {
              this.setState({ error: true, loading: false });
            } else {
              this.setState({
                data: result.data.data,
                total: result.data.total,
                per_page: result.data.per_page,
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({ error: true, loading: false });
          });
      }
    
      editRow = (a) => {};


      render() {

      if (getToken() == null) {
      return <Redirect to="/login"></Redirect>;
    }else if (this.state.loading == true) {
          return <Loading></Loading>;
        } else if (this.state.error == false) {
          const mylist = this.state.data;
          const myview = mylist.map((myList1) => {
            return (
              <tr>
                <td className="customcolor">{myList1.id}</td>
                <td className="customcolor">{myList1.name}</td>
                <td>
                  {/* <Image src={myList1.profileImage} rounded /> */}
                  <img src={myList1.profileImage} alt="No image available" width="100" height="100"></img>
                </td>
                {/* <td className="text-center">
                    <Button
                      onClick={this.editRow.bind(this, myList1)}
                      variant="primary"
                    >
                      Edit
                    </Button>
                  </td> */}
              </tr>
            );
          });
    
          return (
            <Fragment>
              <Container className="mt-5 mb-5">
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="white">id</th>
                          <th className="white">name</th>
                          <th className="white">profileImage</th>
                        </tr>
                      </thead>
                      <tbody>{myview}</tbody>
                    </Table>
    
                    <Pagination
                      activePage={this.state.current_page}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={10}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                      nextPageText="Next"
                      prevPageText="Prev"
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </Col>
                </Row>
              </Container>
            </Fragment>
          );
        }
      }
}

export default AllUserContent;
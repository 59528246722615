import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "../Loading/Loading";
import RestClient from "../restapi/RestClient";
import AppUrl from "../restapi/AppUrl";
import { withRouter, Redirect, Link } from "react-router-dom";
import { getToken } from "../Common/Common";

class TopSliderContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
          current_page: 1,
          total: 0,
          per_page: 10,
          totalusercount: "0",
          totalnewusercount: "0",
          totaluncheckedaudio: "0",
          todayuploadedaudio: "0",
          mydata: [],
          deleteshow: false,
          editshow: false,
          addshow: false,
          loading: true,
          error:false,
          selectedItem: {
            id: 0,
            title: "",
            image_url: "",
            isImage: 0,
            isForSpeaking: 0,
          },
          loggedin:false,
          access_token:""
        };
    
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
    
        this.deleteRow = this.deleteRow.bind(this);
        this.editRow = this.editRow.bind(this);
      }
    
      titleChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            title: e.target.value,
          },
        });
      }
    
      image_urlChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            image_url: e.target.value,
          },
        });
      }
    
      isImageChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            isImage: e.target.value,
          },
        });
      }
    
      isForSpeakingChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            isForSpeaking: e.target.value,
          },
        });
      }
    
      errorToast = (a) =>
        toast.error("🦄 " + a, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      successToast = (a) =>
        toast.success("🦄 " + a, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    
      addCard = () => {
        if (this.state.selectedItem.title == "") {
          alert("Please write Title");
          return;
        }
    
        if (this.state.selectedItem.image_url == "") {
          alert("Please Write image_url");
          return;
        }
        if (this.state.selectedItem.isImage == "") {
          alert("Please Write isImage");
          return;
        }
        if (this.state.selectedItem.isForSpeaking == "") {
          alert("Please Write isForSpeaking");
          return;
        }
    
        this.setState({ addshow: false });

        const token = getToken();
    
        let jsonObject = {
          title: this.state.selectedItem.title,
          image_url: this.state.selectedItem.image_url,
          isImage: this.state.selectedItem.isImage,
          isForSpeaking: this.state.selectedItem.isForSpeaking,
          access_token: token
        };
        RestClient.PostRequest(AppUrl.InsertTopSlide, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null) {
              //this.setState({ error: true, loading: false });
              this.errorToast("Something wrong in server");
              this.setState({ error: true, loading: false });
            } else if (result.success == "0") {
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
              this.setState({ error: true, loading: false });
            } else {
              this.successToast("Successfully saved in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
              this.setState({loading: false });
    
              this.getAllTopSlider();
            }
          })
          .catch((error) => {
            //this.setState({ error: true, loading: false });
            this.setState({ error: true, loading: false });
          });
      };
    
      addRow = () => {
        let newAccess = this.state.selectedItem;
        newAccess.id = 0;
        newAccess.title = "";
        newAccess.image_url = "";
        newAccess.isImage = 0;
        newAccess.isForSpeaking = 0;
        this.setState({ selectedItem: newAccess });
    
        this.setState({ addshow: true });
      };
    
      addClose = () => {
        this.setState({ addshow: false });
      };
    
      editRow = (a) => {
        this.setState({ selectedItem: a });
        this.setState({ editshow: true });
      };
    
      editClose = () => {
        this.setState({ editshow: false });
      };
    
      updateEdited = () => {
        if (this.state.selectedItem.title == "") {
          alert("Please write Title");
          return;
        }
    
        if (this.state.selectedItem.image_url == "") {
          alert("Please Write image_url");
          return;
        }
    
        if (this.state.selectedItem.isImage == "") {
          alert("Please Write isImage");
          return;
        }
    
        if (this.state.selectedItem.isForSpeaking == "") {
          alert("Please Write isForSpeaking");
          return;
        }
    
        this.setState({ editshow: false });

        const token = getToken();
    
        let jsonObject = {
          id: this.state.selectedItem.id,
          title: this.state.selectedItem.title,
          image_url: this.state.selectedItem.image_url,
          isImage: this.state.selectedItem.isImage,
          isForSpeaking: this.state.selectedItem.isForSpeaking,
          access_token: token
        };
        RestClient.PostRequest(AppUrl.updateTopSlide, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null) {
              //this.setState({ error: true, loading: false });
              this.errorToast("Something wrong in server");
              this.setState({ error: true, loading: false });
            } else if (result.success == "0") {
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
              this.setState({ error: true, loading: false });
            } else {
              //alert("Successfully Updated in Database");
              this.successToast("Successfully Updated in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
              this.setState({ loading: false });
    
              this.getAllTopSlider();
            }
          })
          .catch((error) => {
            //this.setState({ error: true, loading: false });
            this.setState({ error: true, loading: false });
          });
      };
    
      deleteClose = () => this.setState({ deleteshow: false });
      //handleShow = () => this.setState({ deleteshow: true });
    
      deleteRow = (a) => {
        this.setState({ selectedItem: a });
        this.setState({ deleteshow: true });
      };
    
      deleteCueCard = () => {
        this.setState({ deleteshow: false });

        const token = getToken();
    
        let jsonObject = {
          id: this.state.selectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.deleteTopSliderByID,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.errorToast("Something wrong in server");
              this.setState({ error: true, loading: false });
              //this.setState({ error: true, loading: false });
            } else if (result.success == "0") {
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
              this.setState({ error: true, loading: false });
            } else {
              //alert("Successfully Deleted in Database");
              this.successToast("Successfully Deleted in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.setState({loading: false });
    
              this.getAllTopSlider();
            }
          })
          .catch((error) => {
            //this.setState({ error: true, loading: false });
            this.setState({ error: true, loading: false });
          });
      };
    
      onPageChanged = (e) => {
        console.log(e.currentPage);
      };
      handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ current_page: pageNumber });
        this.getAllTopSlider(pageNumber);
      }
    
      componentDidMount() {
        // const token = getToken();
        // this.setState({access_token:token});

        this.getAllTopSlider();
      }
    
      getAllTopSlider() {

        const token = getToken();
        let jsonObject = {access_token: token};
        RestClient.PostRequest(AppUrl.getAllTopSlide, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null || result.success == "0") {
              this.setState({ error: true, loading: false });
            } else {
              this.setState({
                mydata: result.data.data,
                total: result.data.total,
                per_page: result.data.per_page,
                loading: false
              });
            }
          })
          .catch((error) => {
            this.setState({ error: true, loading: false });
          });
      }



      render() {
        if (getToken() == null) {
          return <Redirect to="/login"></Redirect>;
        }else if (this.state.loading == true) {
          return <Loading></Loading>;
        } else if(this.state.error == false) {
    
        const mylist = this.state.mydata;
        const myview = mylist.map((myList1) => {
          return (
            <tr>
              <td className="customcolor">{myList1.id}</td>
              <td className="customcolor">{myList1.title}</td>
              {/* <td className="customcolor">{myList1.image_url}</td> */}
              <img className="customcolor" src={myList1.image_url} alt="No image available" width="100" height="100"></img>
              <td className="customcolor">{myList1.isImage}</td>
              <td className="customcolor">{myList1.isForSpeaking}</td>
              <td className="text-center">
                <Button
                  onClick={this.editRow.bind(this, myList1)}
                  variant="primary"
                >
                  Edit
                </Button>
              </td>
              <td className="text-center">
                <Button
                  onClick={this.deleteRow.bind(this, myList1)}
                  variant="danger"
                >
                  Delete
                </Button>
              </td>
            </tr>
          );
        });
    
        return (
          <Fragment>
            <Container className="mt-5 mb-5">
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <ToastContainer />
                  <Button
                    onClick={this.addRow.bind(this)}
                    variant="success"
                    className="float-right"
                  >
                    Add TopSlider
                  </Button>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="white">id</th>
                        <th className="white">title</th>
                        <th className="white">image_url</th>
                        <th className="white">isImage</th>
                        <th className="white">isForSpeaking</th>
                      </tr>
                    </thead>
                    <tbody>{myview}</tbody>
                  </Table>
                  <Modal show={this.state.deleteshow} onHide={this.deleteClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.state.selectedItem.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.selectedItem.title}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.deleteClose}>
                        Close
                      </Button>
                      <Button variant="danger" onClick={this.deleteCueCard}>
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
    
                  <Modal show={this.state.editshow} onHide={this.editClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit TopSlider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card className=" p-3 mb-5 bg-white rounded">
                        <Card.Body>
                          <Form>
                            <Form.Group>
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                id="EcategoryID"
                                type="text"
                                placeholder="Enter title"
                                value={this.state.selectedItem.title}
                                onChange={(e) => this.titleChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>image_url</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter image_url"
                                value={this.state.selectedItem.image_url}
                                onChange={(e) => this.image_urlChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>isImage</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter isImage"
                                value={this.state.selectedItem.isImage}
                                onChange={(e) => this.isImageChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>isForSpeaking</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter isForSpeaking"
                                value={this.state.selectedItem.isForSpeaking}
                                onChange={(e) => this.isForSpeakingChange(e)}
                              />
                            </Form.Group>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.editClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.updateEdited}>
                        Update
                      </Button>
                    </Modal.Footer>
                  </Modal>
    
                  <Modal show={this.state.addshow} onHide={this.addClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add TopSlider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card className=" p-3 mb-5 bg-white rounded">
                        <Card.Body>
                          <Form>
                            <Form.Group>
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                id="EcategoryID"
                                type="text"
                                placeholder="Enter title"
                                value={this.state.selectedItem.title}
                                onChange={(e) => this.titleChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>image_url</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter image_url"
                                value={this.state.selectedItem.image_url}
                                onChange={(e) => this.image_urlChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>isImage</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter isImage"
                                value={this.state.selectedItem.isImage}
                                onChange={(e) => this.isImageChange(e)}
                              />
                            </Form.Group>
    
                            <Form.Group>
                              <Form.Label>isForSpeaking</Form.Label>
                              <Form.Control
                                id="Evideoid"
                                type="text"
                                placeholder="Enter isForSpeaking"
                                value={this.state.selectedItem.isForSpeaking}
                                onChange={(e) => this.isForSpeakingChange(e)}
                              />
                            </Form.Group>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.addClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.addCard}>
                        Add TopSlider
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Pagination
                    activePage={this.state.current_page}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total}
                    pageRangeDisplayed={10}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First"
                    lastPageText="Last"
                    nextPageText="Next"
                    prevPageText="Prev"
                    onChange={this.handlePageChange.bind(this)}
                  />
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
        }
      }
}

export default TopSliderContent;
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Card,
  Form,
  Toast,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestClient from "../restapi/RestClient";
import AppUrl from "../restapi/AppUrl";
import Loading from "../Loading/Loading";
import { withRouter, Redirect, Link } from "react-router-dom";
import { getToken } from "../Common/Common";



class IELTSVideosContent extends Component {



    constructor(props) {
        super(props);
        this.state = {
          catcurrent_page: 1,
          cattotal: 0,
          catper_page: 10,
          catdata: [],
    
          current_page: 1,
          total: 0,
          per_page: 10,
          data: [],
    
          deleteshow: false,
          editshow: false,
          addshow: false,
    
    
          catdeleteshow: false,
          cateditshow: false,
          cataddshow: false,
          loading: true,
          error: false,
          catselectedItem: {
            id: 0,
            title: "",
            videoID: "",
          },
          selectedItem: {
            id: 0,
            categoryID: "",
            videoid: "",
            vtitle: "",
          },
          loggedin:false,
          access_token:""
        };
        this.catdeleteRow = this.catdeleteRow.bind(this);
        this.cateditRow = this.cateditRow.bind(this);
    
        this.deleteRow = this.deleteRow.bind(this);
        this.editRow = this.editRow.bind(this);
      }
    
      componentDidMount() {

        // const token = getToken();
        // this.setState({access_token:token});

        this.getIELTSVideosCategory();
        this.getIELTSVideos();
      }
    
      /// Video
    
      handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ current_page: pageNumber });
        this.getIELTSVideos(pageNumber);
      }
    
      getIELTSVideos(pageNumber = 1) {

        const token = getToken();

        let jsonObject = {access_token: token};
        RestClient.PostRequest(AppUrl.getIELTSVideos + pageNumber,JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null || result.success == "0") {
              this.setState({  loading: false });
            } else {
              this.setState({
                data: result.data.data,
                total: result.data.total,
                per_page: result.data.per_page,
                loading: false,
                error: false,
              });
            }
          })
          .catch((error) => {
            this.setState({  loading: false });
          });
      }
    
      categoryIDChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            categoryID: e.target.value,
          },
        });
      }
    
      videoidChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            videoid: e.target.value,
          },
        });
      }
    
      vtitleChange(e) {
        this.setState({
          selectedItem: {
            ...this.state.selectedItem,
            vtitle: e.target.value,
          },
        });
      }
    
    
      addCard = () => {
        if (this.state.selectedItem.categoryID == "") {
          alert("Please write categoryID");
          return;
        }
    
        if (this.state.selectedItem.videoid == "") {
          alert("Please Write videoid");
          return;
        }
    
        if (this.state.selectedItem.vtitle == "") {
          alert("Please Write vtitle");
          return;
        }
    
        this.setState({ addshow: false });

        const token = getToken();
    
        let jsonObject = {
          categoryID: this.state.selectedItem.categoryID,
          videoid: this.state.selectedItem.videoid,
          vtitle: this.state.selectedItem.vtitle,
          access_token: token
        };
        RestClient.PostRequest(AppUrl.InsertIELTSVideos, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null) {
              this.setState({  loading: false });
              this.errorToast("Something wrong in server");
            } else if (result.success == "0") {
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.setState({ loading: false});
              this.errorToast("Something wrong in server");
            } else {
              this.setState({ loading: false,error: false, });
              this.successToast("Successfully saved in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getIELTSVideos();
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      };
    
      addRow = () => {
        let newAccess = this.state.selectedItem;
        newAccess.id = 0;
        newAccess.categoryID = "";
        newAccess.videoid = "";
        newAccess.vtitle = "";
    
        this.setState({ selectedItem: newAccess });
    
        this.setState({ addshow: true });
      };
    
      addClose = () => {
        this.setState({ addshow: false });
      };
    
      editRow = (a) => {
        this.setState({ selectedItem: a });
        this.setState({ editshow: true });
      };
    
      editClose = () => {
        this.setState({ editshow: false });
      };
    
      updateEdited = () => {
        if (this.state.selectedItem.categoryID == "") {
          alert("Please write categoryID");
          return;
        }
    
        if (this.state.selectedItem.videoid == "") {
          alert("Please Write videoid");
          return;
        }
    
        if (this.state.selectedItem.vtitle == "") {
          alert("Please Write vtitle");
          return;
        }
    
        this.setState({ editshow: false });

        const token = getToken();
    
        let jsonObject = {
          categoryID: this.state.selectedItem.categoryID,
          videoid: this.state.selectedItem.videoid,
          vtitle: this.state.selectedItem.vtitle,
          id: this.state.selectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(AppUrl.updateIELTSVideos, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null) {
              this.setState({  loading: false });
              this.errorToast("Something wrong in server");
            } else if (result.success == "0") {
              this.setState({ loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
            } else {
              this.setState({ loading: false,error: false, });
              //alert("Successfully Updated in Database");
              this.successToast("Successfully Updated in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getIELTSVideos();
            }
          })
          .catch((error) => {
            this.setState({  loading: false });
          });
      };
    
      deleteClose = () => this.setState({ deleteshow: false });
      //handleShow = () => this.setState({ deleteshow: true });
    
      deleteRow = (a) => {
        this.setState({ selectedItem: a });
        this.setState({ deleteshow: true });
      };
    
      deleteCueCard = () => {
        this.setState({ deleteshow: false });

        const token = getToken();
    
        let jsonObject = {
          id: this.state.selectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.deleteIELTSVideoBYID,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.errorToast("Something wrong in server");
              this.setState({  loading: false });
            } else if (result.success == "0") {
              this.setState({ loading: false});
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
            } else {
              this.setState({ loading: false,error: false, });
              //alert("Successfully Deleted in Database");
              this.successToast("Successfully Deleted in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getIELTSVideos();
            }
          })
          .catch((error) => {
            this.setState({  loading: false });
          });
      };
    
      // Category video
    
      cathandlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ catcurrent_page: pageNumber });
        this.getIELTSVideosCategory(pageNumber);
      }
    
      getIELTSVideosCategory(pageNumber = 1) {

        const token = getToken();
        let jsonObject = {access_token: token};

        RestClient.PostRequest(AppUrl.getIELTScatVideos + pageNumber, JSON.stringify(jsonObject))
          .then((result) => {
            if (result == null || result.success == "0") {
              this.setState({ loading: false });
            } else {
              this.setState({
                catdata: result.data.data,
                cattotal: result.data.total,
                catper_page: result.data.per_page,
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      }
    
      cattitleChange(e) {
        this.setState({
          catselectedItem: {
            ...this.state.catselectedItem,
            title: e.target.value,
          },
        });
      }
    
      catvideoIDChange(e) {
        this.setState({
          catselectedItem: {
            ...this.state.catselectedItem,
            videoID: e.target.value,
          },
        });
      }
    
      errorToast = (a) =>
        toast.error("🦄 " + a, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      successToast = (a) =>
        toast.success("🦄 " + a, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    
      cataddCard = () => {
        if (this.state.catselectedItem.title == "") {
          alert("Please write Title");
          return;
        }
    
        if (this.state.catselectedItem.videoID == "") {
          alert("Please Write videoID");
          return;
        }
    
        this.setState({ cataddshow: false });

        const token = getToken();
    
        let jsonObject = {
          title: this.state.catselectedItem.title,
          videoID: this.state.catselectedItem.videoID,
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.InsertIELTSCatVideos,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.setState({ loading: false });
              this.errorToast("Something wrong in server");
            } else if (result.success == "0") {
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.setState({ loading: false });
              this.errorToast("Something wrong in server");
            } else {
              this.setState({ loading: false });
              this.successToast("Successfully saved in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getIELTSVideosCategory();
            }
          })
          .catch((error) => {
            this.setState({  loading: false });
          });
      };
    
      cataddRow = () => {
        let newAccess = this.state.catselectedItem;
        newAccess.id = 0;
        newAccess.title = "";
        newAccess.videoID = "";
        this.setState({ catselectedItem: newAccess });
    
        this.setState({ cataddshow: true });
      };
    
      cataddClose = () => {
        this.setState({ cataddshow: false });
      };
    
      cateditRow = (a) => {
        this.setState({ catselectedItem: a });
        this.setState({ cateditshow: true });
      };
    
      cateditClose = () => {
        this.setState({ cateditshow: false });
      };
    
      catupdateEdited = () => {
        if (this.state.catselectedItem.title == "") {
          alert("Please write Title");
          return;
        }
    
        if (this.state.catselectedItem.videoID == "") {
          alert("Please Write videoID");
          return;
        }
    
        this.setState({ cateditshow: false });

        const token = getToken();
    
        let jsonObject = {
          title: this.state.catselectedItem.title,
          videoID: this.state.catselectedItem.videoID,
          id: this.state.catselectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.updateIELTSCatVideos,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.setState({  loading: false });
              this.errorToast("Something wrong in server");
            } else if (result.success == "0") {
              this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
            } else {
              this.setState({ loading: false });
              //alert("Successfully Updated in Database");
              this.successToast("Successfully Updated in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getIELTSVideosCategory();
            }
          })
          .catch((error) => {
            this.setState({  loading: false });
          });
      };
    
      catdeleteClose = () => this.setState({ catdeleteshow: false });
      //handleShow = () => this.setState({ deleteshow: true });
    
      catdeleteRow = (a) => {
        this.setState({ catselectedItem: a });
        this.setState({ catdeleteshow: true });
      };
    
      catdeleteCueCard = () => {
        this.setState({ catdeleteshow: false });

        const token = getToken();
    
        let jsonObject = {
          id: this.state.catselectedItem.id,
          access_token: token
        };
        RestClient.PostRequest(
          AppUrl.deleteIELTSCatBYID,
          JSON.stringify(jsonObject)
        )
          .then((result) => {
            if (result == null) {
              this.errorToast("Something wrong in server");
              this.setState({  loading: false });
            } else if (result.success == "0") {
              this.setState({ loading: false });
              //this.setState({ error: false, loading: false });
              //this.setState({ message: "No account found on server" });
              // alert("No account found on server");
              //alert("Something wrong in server");
              this.errorToast("Something wrong in server");
            } else {
              this.setState({ loading: false });
              //alert("Successfully Deleted in Database");
              this.successToast("Successfully Deleted in Database");
              //this.setState({ mydata: result.data, loading: false, error: false });
    
              this.getIELTSVideosCategory();
            }
          })
          .catch((error) => {
            this.setState({  loading: false });
          });
      };
    



      render() {

        if (getToken() == null) {
          return <Redirect to="/login"></Redirect>;
        }else if (this.state.loading == true) {
          return <Loading></Loading>;
        } else if (this.state.error == false) {
          const mylist = this.state.catdata;
          const myview = mylist.map((myList1) => {
            return (
              <tr>
                <td className="customcolor">{myList1.id}</td>
                <td className="customcolor">{myList1.title}</td>
                <td className="customcolor">{myList1.videoID}</td>
                <img src={`http://img.youtube.com/vi/${myList1.videoID}/default.jpg`} title="YouTube Video" alt="YouTube Video" />
                <td className="text-center">
                  <Button
                    onClick={this.cateditRow.bind(this, myList1)}
                    variant="primary"
                  >
                    Edit
                  </Button>
                </td>
                <td className="text-center">
                  <Button
                    onClick={this.catdeleteRow.bind(this, myList1)}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          });
    
          // videos
    
          const mylist1 = this.state.data;
          const myview1 = mylist1.map((myList1) => {
            return (
              <tr>
                <td className="customcolor">{myList1.id}</td>
                <td className="customcolor">{myList1.categoryID}</td>
                <td className="customcolor">{myList1.videoid}</td>
                {/* <td>{myList1.vtitle}</td> */}
                <img src={`http://img.youtube.com/vi/${myList1.videoid}/default.jpg`} title="YouTube Video" alt="YouTube Video" />
                <td className="text-center">
                  <Button
                    onClick={this.editRow.bind(this, myList1)}
                    variant="primary"
                  >
                    Edit
                  </Button>
                </td>
                <td className="text-center">
                  <Button
                    onClick={this.deleteRow.bind(this, myList1)}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          });
    
          return (
            <Fragment>
              <Container className="mt-5 mb-5">
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <ToastContainer />
                    <Button
                      onClick={this.cataddRow.bind(this)}
                      variant="success"
                      className="float-right"
                    >
                      Add Category
                    </Button>
    
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="white">id</th>
                          <th className="white">title</th>
                          <th className="white">videoID</th>
                        </tr>
                      </thead>
                      <tbody>{myview}</tbody>
                    </Table>
    
                    <Modal
                      show={this.state.catdeleteshow}
                      onHide={this.catdeleteClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{this.state.catselectedItem.id}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{this.state.catselectedItem.title}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.catdeleteClose}>
                          Close
                        </Button>
                        <Button variant="danger" onClick={this.catdeleteCueCard}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
    
                    <Modal show={this.state.cateditshow} onHide={this.cateditClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Category</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Card className=" p-3 mb-5 bg-white rounded">
                          <Card.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                  id="EcategoryID"
                                  type="text"
                                  placeholder="Enter title"
                                  value={this.state.catselectedItem.title}
                                  onChange={(e) => this.cattitleChange(e)}
                                />
                              </Form.Group>
    
                              <Form.Group>
                                <Form.Label>videoID</Form.Label>
                                <Form.Control
                                  id="Evideoid"
                                  type="text"
                                  placeholder="Enter videoID"
                                  value={this.state.catselectedItem.videoID}
                                  onChange={(e) => this.catvideoIDChange(e)}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.cateditClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={this.catupdateEdited}>
                          Update
                        </Button>
                      </Modal.Footer>
                    </Modal>
    
                    <Modal show={this.state.cataddshow} onHide={this.cataddClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Category</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Card className=" p-3 mb-5 bg-white rounded">
                          <Card.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                  id="EcategoryID"
                                  type="text"
                                  placeholder="Enter title"
                                  value={this.state.catselectedItem.title}
                                  onChange={(e) => this.cattitleChange(e)}
                                />
                              </Form.Group>
    
                              <Form.Group>
                                <Form.Label>videoID</Form.Label>
                                <Form.Control
                                  id="Evideoid"
                                  type="text"
                                  placeholder="Enter videoID"
                                  value={this.state.catselectedItem.videoID}
                                  onChange={(e) => this.catvideoIDChange(e)}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.cataddClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={this.cataddCard}>
                          Add CueCard
                        </Button>
                      </Modal.Footer>
                    </Modal>
    
                    <Pagination
                      activePage={this.state.catcurrent_page}
                      itemsCountPerPage={this.state.catper_page}
                      totalItemsCount={this.state.cattotal}
                      pageRangeDisplayed={10}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                      nextPageText="Next"
                      prevPageText="Prev"
                      onChange={this.cathandlePageChange.bind(this)}
                    />
    
                    <Button
                      onClick={this.addRow.bind(this)}
                      variant="success"
                      className="float-right"
                    >
                      Add Videos
                    </Button>
    
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="white">id</th>
                          <th className="white">categoryID</th>
                          <th className="white">videoid</th>
                          
                        </tr>
                      </thead>
                      <tbody>{myview1}</tbody>
                    </Table>
    
                    <Modal show={this.state.deleteshow} onHide={this.deleteClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>{this.state.selectedItem.id}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{this.state.selectedItem.categoryID}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.deleteClose}>
                          Close
                        </Button>
                        <Button variant="danger" onClick={this.deleteCueCard}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
    
                    <Modal show={this.state.editshow} onHide={this.editClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Cue Card</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Card className=" p-3 mb-5 bg-white rounded">
                          <Card.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>categoryID</Form.Label>
                                <Form.Control
                                  id="EcategoryID"
                                  type="text"
                                  placeholder="Enter title"
                                  value={this.state.selectedItem.categoryID}
                                  onChange={(e) => this.categoryIDChange(e)}
                                />
                              </Form.Group>
    
                              <Form.Group>
                                <Form.Label>videoid</Form.Label>
                                <Form.Control
                                  id="Evideoid"
                                  type="text"
                                  placeholder="Enter date"
                                  value={this.state.selectedItem.videoid}
                                  onChange={(e) => this.videoidChange(e)}
                                />
                              </Form.Group>
    
    
                              <Form.Group>
                                <Form.Label>vtitle</Form.Label>
                                <Form.Control
                                  id="Evideoid"
                                  type="text"
                                  placeholder="Enter date"
                                  value={this.state.selectedItem.vtitle}
                                  onChange={(e) => this.vtitleChange(e)}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.editClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={this.updateEdited}>
                          Update
                        </Button>
                      </Modal.Footer>
                    </Modal>
    
                    <Modal show={this.state.addshow} onHide={this.addClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Videos</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Card className=" p-3 mb-5 bg-white rounded">
                          <Card.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>categoryID</Form.Label>
                                <Form.Control
                                  id="EcategoryID"
                                  type="text"
                                  placeholder="Enter title"
                                  value={this.state.selectedItem.categoryID}
                                  onChange={(e) => this.categoryIDChange(e)}
                                />
                              </Form.Group>
    
                              <Form.Group>
                                <Form.Label>videoid</Form.Label>
                                <Form.Control
                                  id="Evideoid"
                                  type="text"
                                  placeholder="Enter date"
                                  value={this.state.selectedItem.videoid}
                                  onChange={(e) => this.videoidChange(e)}
                                />
                              </Form.Group>
    
                              <Form.Group>
                                <Form.Label>vtitle</Form.Label>
                                <Form.Control
                                  id="Evideoid"
                                  type="text"
                                  placeholder="Enter date"
                                  value={this.state.selectedItem.vtitle}
                                  onChange={(e) => this.vtitleChange(e)}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.addClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={this.addCard}>
                          Add Videos
                        </Button>
                      </Modal.Footer>
                    </Modal>
    
                    <Pagination
                      activePage={this.state.current_page}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={10}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                      nextPageText="Next"
                      prevPageText="Prev"
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </Col>
                </Row>
              </Container>
            </Fragment>
          );
        }
      }
}

export default IELTSVideosContent;
import React, { Component, Fragment } from 'react';
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer';
import IELTSVideosContent from '../components/IELTSVideosContent/IELTSVideosContent';

class IELTSVideoPage extends Component {


  render() {
    return (
      <Fragment>

        <TopNav></TopNav>
        <IELTSVideosContent></IELTSVideosContent>
        <Footer></Footer>
        
      </Fragment>
    );
  }
}

export default IELTSVideoPage;